import React from "react";
import { Div, Text, Anchor, Image } from "atomize";

const HeroCard = (props) => {
  const { url, cover, icon, title, subtitle, transformCoordinate, disabled } = props;
  return (
    <Div
      m={{
        x: { xs: ".25rem", sm: "10rem", xl: "20rem" },
      }}
      p={{ b: "1rem" }}
    >
      <Anchor href={url} target="_blank" style={disabled ? {pointerEvents:"none"} : null}>
        <Div
          bgImg={cover}
          bgSize="cover"
          bgPos="center"
          h="300px"
          shadow="4"
          rounded="xl"
          m={{ b: "0.5rem" }}
        >
          <Div
            bg={disabled ? "rgba(0, 0, 0, 0.80)" : "rgba(0, 0, 0, 0.45)"}
            d="block"
            h="300px"
            rounded="xl"
            m={{ b: "0.5rem" }}
            style={{zIndex: "0"}}
          />
          <Image
            pos="absolute"
            src={icon}
            h="50px"
            w="auto"
            left="50%"
            m={{ t: { xs: "-50%", sm: "-21%", lg:"-16%", xl: "-11%" }}}
            transform="translateX(-50%)"
          />
          <Div
            d="flex"
            flexDir="column"
            p="1rem"
            top="100px"
            transform={transformCoordinate}
          >
            <Text
              textSize={{ xs: "heading", xl: "display1" }}
              style={{ lineHeight: "30px" }}
              textWeight="700"
              textColor="#ffcc00"
            >
              {title}{disabled && (<span> - (Coming Soon)</span>)}
            </Text>
            {!disabled && (<Text
              textSize={{ xs: "caption", xl: "subheader" }}
              textColor="gray100"
              textWeight="400"
            >
              {subtitle}
            </Text>)}
          </Div>
        </Div>
      </Anchor>
    </Div>
  );
};

export default HeroCard;
