import React from 'react';
import { Div, Icon, Text, Button, Anchor, Col } from 'atomize';
import profilePicture from '../assets/DSC03801-Edit.JPG';

const HeroCard = () => {
  return (
    <Div
      // give a width and maxWidth
      // do margin auto?
      m={{
        x: { xs: '.25rem', sm: '10rem', xl: '20rem' },
        t: { xs: '2rem', xl: '4rem' },
      }}
      p={{ b: '1rem' }}
    >
      <Div bg={'#16161A'} shadow="4" rounded="xl" m={{ b: '1rem' }} p="1.5rem">
        <Col d="flex" justify="center">
          <Div
            bgImg={profilePicture}
            bgSize="cover"
            bgPos="center"
            h="5rem"
            w="5rem"
            rounded="circle"
            m={{ b: '1rem' }}
          />
        </Col>
        <Div d="flex" align="center" flexDir="column">
          <Text textSize="title" textWeight="500" textColor="gray100">
            Aral Tasher
          </Text>
          <Text textSize="caption" textColor="light">
            @araltasher
          </Text>
        </Div>
        {/* <Tag
          d="flex"
          bg={'rgba(114, 117, 126, .3)'}
          rounded="lg"
          h="50px"
          border="1px solid"
          textSize={{ xs: 'tiny', sm: 'caption' }}
          borderColor={`gray500`}
          textColor={`gray800`}
        >
          <Icon
            name="AlertSolid"
            size="20px"
            m={{ r: '10px' }}
            color="gray800"
          />
          <Text textSize={{ xs: 'tiny', sm: 'caption' }} textWeight="800">
            NOT ACCEPTING
          </Text>
          <Text textSize={{ xs: 'tiny', sm: 'caption' }}>
            &nbsp; Freelance Projects
          </Text>
        </Tag> */}
        <Div m={{ b: '0.25rem', t: '1rem' }} d="flex" justify="space-evenly">
          <Anchor href="https://araltasher.com" target="_blank">
            <Button
              h="2.5rem"
              w="2.5rem"
              bg="#333333"
              hoverBg="#6BCBA4"
              rounded="circle"
              m={{ r: '1rem' }}
              shadow="2"
              hoverShadow="4"
            >
              <Icon name="HomeSolid2" size="20px" color="white" />
            </Button>
          </Anchor>
          <Anchor href="https://youtube.com/c/AralTasher" target="_blank">
            <Button
              h="2.5rem"
              w="2.5rem"
              bg="danger700"
              hoverBg="danger600"
              rounded="circle"
              m={{ r: '1rem' }}
              shadow="2"
              hoverShadow="4"
            >
              <Icon name="Play" size="20px" color="white" />
            </Button>
          </Anchor>
          <Anchor href="https://www.tiktok.com/@thearaltasher" target="_blank">
            <Button
              h="2rem"
              p={{ x: '0.75rem' }}
              textSize="caption"
              textColor="dark"
              textWeight="600"
              hoverTextColor="gray100"
              bg="#00f2ea"
              hoverBg="#ff0050"
              border="1px solid"
              m={{ t: '5px', r: '1rem' }}
              shadow="2"
              hoverShadow="4"
            >
              TikTok
            </Button>
          </Anchor>
          <Anchor href="https://www.facebook.com/thearaltasher" target="_blank">
            <Button
              h="2.5rem"
              w="2.5rem"
              bg="#3b5998"
              hoverBg="#8b9dc3"
              rounded="circle"
              m={{ r: '1rem' }}
              shadow="2"
              hoverShadow="4"
            >
              <Icon name="Facebook" size="20px" color="white" />
            </Button>
          </Anchor>
          <Anchor href="https://twitter.com/araltasher" target="_blank">
            <Button
              h="2.5rem"
              w="2.5rem"
              bg="#1da1f2"
              hoverBg="#7ac8f7"
              rounded="circle"
              m={{ r: '1rem' }}
              shadow="2"
              hoverShadow="4"
            >
              <Icon name="Twitter" size="20px" color="white" />
            </Button>
          </Anchor>
        </Div>
      </Div>
    </Div>
  );
};

export default HeroCard;
