import React from "react";
import { Div, Col, Row, Anchor, Text } from "atomize";
import DesktopImg from '../assets/desktop.jpg';
import S23UltraImg from '../assets/s23Ultra.jpg';

const SmallCards = (props) => {
  return (
    <Div
      m={{
        x: { xs: ".25rem", sm: "10rem", xl: "20rem" },
      }}
      p={{ b: "1rem" }}
    >
      <Row>
        <Col size={{ xs: 12, md: 6 }}>
          <Anchor
            href={
              "https://unsplash.com/photos/Oi_oaJk3qlo"
            }
            target="_blank"
          >
            <Div
              p="1rem"
              d="flex"
              flexDir="column"
              justify="space-around"
              bgImg={DesktopImg}
              bgSize="cover"
          bgPos="center"
              shadow="4"
              rounded="xl"
              m={{ b: "1rem" }}
            >
              <Text
                textSize={{ xs: "title", xl: "display1" }}
                maxW={{ xs: "2rem", md: "80%" }}
                textWeight="900"
                textColor="gray100"
                p="1.5rem"
                style={{ lineHeight: "2rem" }}
              >
                Desktop Wallpaper
              </Text>
              <Text
                m={{ t: { xs: "0.5rem" } }}
                p={{ l: { xs: "1.5rem" } }}
                textSize={{ xs: "tiny", xl: "caption" }}
                textColor="light"
              >
                Download Wallpaper
              </Text>
              {/* <Text
                textSize={{ xs: "caption", xl: "subheader" }}
                textColor="#2CB67D"
                textWeight="800"
              >
                WFH20-ARALTASHER
              </Text> */}
            </Div>
          </Anchor>
        </Col>
        <Col size={{ xs: 12, md: 6 }}>
          <Anchor
            href={
              "https://unsplash.com/photos/j6IEwWmiWtk"
            }
            target="_blank"
          >
            <Div
              p="1rem"
              d="flex"
              flexDir="column"
              justify="space-around"
              bgImg={S23UltraImg}
              bgSize="cover"
          bgPos="center"
              shadow="4"
              rounded="xl"
              m={{ b: "1rem" }}
            >
              <Text
                textSize={{ xs: "title", xl: "display1" }}
                maxW={{ xs: "2rem", md: "80%" }}
                textWeight="900"
                textColor="gray100"
                p="1.5rem"
                style={{ lineHeight: "2rem" }}
              >
                S24 Ultra Wallpaper
              </Text>
              <Text
                m={{ t: { xs: "0.5rem" } }}
                p={{ l: { xs: "1.5rem" } }}
                textSize={{ xs: "tiny", xl: "caption" }}
                textColor="light"
              >
                Download Wallpaper
              </Text>
            </Div>
          </Anchor>
        </Col>
      </Row>
    </Div>
  );
};

export default SmallCards;
