import React, { useEffect, useState } from "react";
import { Div, Text, Anchor } from "atomize";

const Footer = () => {
  const [date, setDate] = useState(0);

  useEffect(() => {
    let newDate = new Date();
    setDate(newDate.getFullYear());
  }, []);
  return (
    <Div
      d="flex"
      justify="center"
      w="100%"
      m={{ t: "1rem" }}
      p={{ y: "0.8rem" }}
      bg="#16161a"
    >
      <Text textColor="#94a1b2">
        Copyright{" "}
        <Anchor
          textColor="#2cb67d"
          hoverTextColor="#AAE1CB"
          href="https://araltasher.com"
          target="_blank"
          style={{
            transition: "all .35s ease-in-out",
          }}
        >
          Aral Tasher©
        </Anchor>{" "}
        {date}
      </Text>
    </Div>
  );
};

export default Footer;
