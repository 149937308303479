import React from "react";
import { Div, Text } from "atomize";
import { useLoading, Bars } from "@agney/react-loading";

const Loader = () => {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    loaderProps: {
      style: { fill: "#2cb67d" },
    },
    indicator: <Bars width="50" />,
  });

  return (
    <Div
      h="100vh"
      d="flex"
      flexDir="column"
      justify="center"
      align="center"
      {...containerProps}
    >
      <Div>
        {indicatorEl} {/* renders only while loading */}
      </Div>
      <Text textSize="subheader" textColor="gray100" textWeight="700">
        {" "}
        Figuring out some links, just a sec
      </Text>
    </Div>
  );
};

export default Loader;
